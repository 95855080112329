/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import ActivityWidget from '../elements/EventWidget';
import OverdueWidget from '../elements/OverdueWidget';
import Analytics from '../components/analytics/Analytics';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaExclamationTriangle } from 'react-icons/fa';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '../css/Dashboard.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = ({ user }) => {
  const [widgetsCollapsed, setWidgetsCollapsed] = useState(false);

  const renderWidgetsSection = () => (
    <div key="widgets" className="dashboard-section">
      <div className="section-header" onClick={() => setWidgetsCollapsed(!widgetsCollapsed)}>
        <h2>
          Widgets
          {widgetsCollapsed ? <FaChevronDown /> : <FaChevronUp />}
        </h2>
      </div>
      
      {!widgetsCollapsed && (
        <div className="section-content">
          <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
            <div className="widget-container">
              <ActivityWidget user={user} />
            </div>
            <div className="widget-container">
              <OverdueWidget />
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const layout = {
    lg: [
      { i: 'analytics', x: 0, y: 0, w: 12, h: 40, minH: 40, maxH: 40 },
      { i: 'widgets', x: 0, y: 40, w: 12, h: widgetsCollapsed ? 2 : 12 },
    ],
    md: [
      { i: 'analytics', x: 0, y: 0, w: 12, h: 40, minH: 40, maxH: 40 },
      { i: 'widgets', x: 0, y: 40, w: 12, h: widgetsCollapsed ? 2 : 12 },
    ],
    sm: [
      { i: 'analytics', x: 0, y: 0, w: 12, h: 40, minH: 40, maxH: 40 },
      { i: 'widgets', x: 0, y: 40, w: 12, h: widgetsCollapsed ? 2 : 12 },
    ],
    xs: [
      { i: 'analytics', x: 0, y: 0, w: 12, h: 40, minH: 40, maxH: 40 },
      { i: 'widgets', x: 0, y: 40, w: 12, h: widgetsCollapsed ? 2 : 12 },
    ],
    xxs: [
      { i: 'analytics', x: 0, y: 0, w: 12, h: 40, minH: 40, maxH: 40 },
      { i: 'widgets', x: 0, y: 40, w: 12, h: widgetsCollapsed ? 2 : 12 },
    ],
  };

  return (
    <div className="dashboard-container" style={{ padding: '20px' }}>
      <div className="development-warning">
        <FaExclamationTriangle />
        <span>This dashboard is currently under development. Some information may be inaccurate or outdated.</span>
      </div>
      <h1>Добар ден, {user.displayName}</h1>
      <ResponsiveGridLayout
        className="layout"
        layouts={layout}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        rowHeight={30}
        margin={[20, 20]}
        containerPadding={[0, 0]}
        isDraggable={false}
        isResizable={false}
      >
        {renderWidgetsSection()}
        <div key="analytics">
          <Analytics />
        </div>
      </ResponsiveGridLayout>
    </div>
  );
};

export default Dashboard;
