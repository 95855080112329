/* eslint-disable react/prop-types */
import React from 'react';
import '../css/OverdueWidget.css';
import { NavLink } from 'react-router-dom';

const OverdueWidget = ({ orders }) => {
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const parseFormattedNumber = (numberStr) => {
    return parseFloat(numberStr.replace(/,/g, ''));
  };

  const getDueDateClass = (dueDate) => {
    const today = new Date();
    const due = parseDate(dueDate);
    const diffTime = due - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 1) return 'purple';
    if (diffDays < 7) return 'pale-orange';
    if (diffDays < 15) return 'pale-yellow';
    if (diffDays < 30) return 'pale-green';
    if (diffDays < 60) return 'pale-blue';
    if (diffDays < 90) return 'gray';
    return '';
  };

  return (
    <div className="overdue-widget">
      <div className="widget-header">
        <h2>Фактури со изминат рок</h2>
        <NavLink to="/debtors" exact activeClassName="active" className="all-events-link">
          Сите Должници
        </NavLink>
      </div>

      <div className="widget-content">
        {orders && orders.length > 0 ? (
          <table className="orders-table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Invoice Number</th>
                <th>Buyer</th>
                <th>Amount Due</th>
                <th>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => {
                const rowClass = getDueDateClass(order.dueDate);
                return (
                  <tr key={order.OrderID} className={rowClass}>
                    <td>{order.OrderID}</td>
                    <td>{order.invoiceNumber}</td>
                    <td>{order.Buyer}</td>
                    <td>{formatNumber(parseFormattedNumber(order.totalSum) - parseFormattedNumber(order.Advance) || 0)}</td>
                    <td>{order.dueDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="no-orders">Нема фактури со изминат рок</div>
        )}
      </div>
    </div>
  );
};

export default OverdueWidget;
