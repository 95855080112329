import React from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import './Analytics.css';

const KPICard = ({ title, value, currency, unit, comparison, inverseColors }) => {
  const formatComparison = (value) => {
    if (value === null || value === undefined) return null;
    return value.toFixed(1);
  };

  const getComparisonColor = (value) => {
    if (value === null || value === undefined) return '';
    if (inverseColors) {
      // For metrics where decrease is positive (like payment latency)
      if (value < 0) return 'positive';
      if (value > 0) return 'negative';
    } else {
      // For regular metrics where increase is positive
      if (value > 0) return 'positive';
      if (value < 0) return 'negative';
    }
    return '';
  };

  return (
    <div className="kpi-card">
      <div className="kpi-header">
        <span className="kpi-title">{title}</span>
      </div>
      <div className="kpi-content">
        <div className="kpi-value">
          {value}
          {currency && <span className="kpi-currency">{currency}</span>}
          {unit && <span className="kpi-unit">{unit}</span>}
        </div>
        {comparison !== null && comparison !== undefined && (
          <div className={`kpi-comparison ${getComparisonColor(comparison)}`}>
            {comparison > 0 ? <FaArrowUp /> : <FaArrowDown />}
            {Math.abs(formatComparison(comparison))}%
          </div>
        )}
      </div>
    </div>
  );
};

export default KPICard;
