import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './Analytics.css';

const TopCompaniesChart = ({ data, metric = 'value', unit = '', onItemClick, isSingleCompany }) => {
  const formatValue = (value) => {
    if (metric === 'latency') {
      return `${value} ${unit}`;
    } else if (metric === 'income') {
      return `${value.toLocaleString()} ${unit}`;
    }
    return value;
  };

  const getBarColor = () => {
    switch (metric) {
      case 'latency':
        return '#ff4d4f';  // Red for latency (negative metric)
      case 'income':
        return '#52c41a';  // Green for income (positive metric)
      default:
        return '#1890ff'; // Default blue
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{
          backgroundColor: 'white',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px'
        }}>
          <p style={{ margin: 0 }}><strong>{label}</strong></p>
          <p style={{ margin: 0, color: getBarColor() }}>
            {formatValue(payload[0].value)}
          </p>
        </div>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (isSingleCompany) {
      return (
        <div className="recent-orders">
          <h3>Recent Orders</h3>
          <div className="orders-list">
            {data.map((order, index) => (
              <div key={index} className="order-item">
                <div className="order-date">{new Date(order.date).toLocaleDateString()}</div>
                <div className="order-amount">{order.amount.toLocaleString()} {order.currency}</div>
                <div className="order-latency">{order.latency} days</div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 60
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            angle={-45}
            textAnchor="end"
            interval={0}
            height={60}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            tickFormatter={formatValue}
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="value"
            fill={getBarColor()}
            radius={[4, 4, 0, 0]}
            onClick={(data) => onItemClick && onItemClick(data.name)}
            cursor="pointer"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="chart-container">
      {renderContent()}
    </div>
  );
};

export default TopCompaniesChart;
