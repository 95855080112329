import React, { memo, useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from 'react-simple-maps';
import { scaleQuantize } from 'd3-scale';
import { Tooltip } from 'react-tooltip';
import { FaSearchPlus, FaSearchMinus, FaExpand } from 'react-icons/fa';
import './Analytics.css';

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

// Map of country names to ISO codes
const countryNames = {
  'MK': 'Macedonia',
  'FR': 'France',
  'DE': 'Germany',
  'IT': 'Italy',
  'GB': 'United Kingdom',
  'ES': 'Spain',
  'NL': 'Netherlands',
  'BE': 'Belgium',
  'CH': 'Switzerland',
  'AT': 'Austria',
  'PL': 'Poland',
  'CZ': 'Czech Republic',
  'SK': 'Slovakia',
  'HU': 'Hungary',
  'RO': 'Romania',
  'BG': 'Bulgaria',
  'HR': 'Croatia',
  'SI': 'Slovenia',
  'RS': 'Serbia',
  'ME': 'Montenegro',
  'AL': 'Albania',
  'GR': 'Greece',
  'TR': 'Turkey'
};

// Reverse mapping for display
const isoToName = Object.entries(countryNames).reduce((acc, [code, name]) => {
  acc[name] = code;
  return acc;
}, {});

const CountryHeatmap = ({ data, metric = 'orders', onCountryClick }) => {
  const [position, setPosition] = useState({ coordinates: [15, 50], zoom: 4 });

  const handleZoomIn = () => {
    if (position.zoom >= 8) return;
    setPosition(pos => ({ ...pos, zoom: pos.zoom * 1.5 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 1) return;
    setPosition(pos => ({ ...pos, zoom: pos.zoom / 1.5 }));
  };

  const handleReset = () => {
    setPosition({ coordinates: [15, 50], zoom: 4 });
  };

  // Transform data from ISO codes to country names for the map
  const transformedData = Object.entries(data).reduce((acc, [code, value]) => {
    const countryName = countryNames[code];
    if (countryName) {
      acc[countryName] = value;
    }
    return acc;
  }, {});

  const colorScale = scaleQuantize()
    .domain([0, Math.max(...Object.values(transformedData)) || 1])
    .range([
      "#cfe2f3",
      "#9fc5e8",
      "#6fa8dc",
      "#3d85c6",
      "#0b5394"
    ]);

  const formatValue = (value) => {
    if (metric === 'income') {
      return `€${value.toLocaleString()}`;
    }
    return value.toLocaleString();
  };

  return (
    <div className="map-container">
      <div className="map-controls">
        <button onClick={handleZoomIn} className="map-control-btn">
          <FaSearchPlus />
        </button>
        <button onClick={handleZoomOut} className="map-control-btn">
          <FaSearchMinus />
        </button>
        <button onClick={handleReset} className="map-control-btn">
          <FaExpand />
        </button>
      </div>
      <ComposableMap
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 800
        }}
        width={800}
        height={300}
      >
        <ZoomableGroup
          center={position.coordinates}
          zoom={position.zoom}
          onMoveEnd={({ coordinates, zoom }) => setPosition({ coordinates, zoom })}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => {
                const countryName = geo.properties.name;
                const value = transformedData[countryName] || 0;
                const isoCode = isoToName[countryName];
                
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    data-tooltip-id="country-tooltip"
                    data-tooltip-content={`${countryName} (${isoCode || ''}): ${formatValue(value)} ${metric}`}
                    style={{
                      default: {
                        fill: value ? colorScale(value) : "#F5F4F6",
                        stroke: "#D6D6DA",
                        strokeWidth: 0.5,
                        outline: "none"
                      },
                      hover: {
                        fill: value ? "#1e88e5" : "#F5F4F6",
                        stroke: "#D6D6DA",
                        strokeWidth: 0.5,
                        outline: "none"
                      },
                      pressed: {
                        fill: value ? "#0b5394" : "#F5F4F6",
                        stroke: "#D6D6DA",
                        strokeWidth: 0.5,
                        outline: "none"
                      }
                    }}
                    onClick={() => {
                      if (isoCode && onCountryClick) {
                        onCountryClick(isoCode);
                      }
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <Tooltip 
        id="country-tooltip" 
        style={{
          backgroundColor: 'white',
          color: '#333',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          padding: '8px 12px',
          borderRadius: '4px',
          fontSize: '14px'
        }}
      />
    </div>
  );
};

export default memo(CountryHeatmap);
