/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import '../css/ordersTable.css';
import InvoiceIcon from '../Icons/InvoiceIcon.js';
import EditOrderForm from './EditOrderForm.js';
import GenerateInvoiceModal from './GenerateInvoiceModal.js'; // Import GenerateInvoiceModal
import CompanyInfoModal from './CompanyInfoModal'; // Import the CompanyInfoModal component
import { ref, update, get } from 'firebase/database';
import { db, db2 } from '../firebase.js';
import InvoiceMultipleModal from './GenerateInvoiceOAMultipleModal.js';
import Invoice from './GeneratePDFInvoice.js';

const OrdersTable = ({ user, userRole, orders, vendor }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [editingOrder, setEditingOrder] = useState(null);
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);
  const [showMultipleInvoiceModal, setShowMultipleInvoiceModal] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);

  const handleEditOrder = (order) => {
    setEditingOrder(order);
  };

  const handleCloseModal = () => {
    setEditingOrder(null);
  };

  const handleSendToDebtors = async (order) => {
    if (!order.validatedInvoice) {
      alert("Нарачката ги нема сите инфромации за фактура. ве молам потврдете дека се е во ред");
      return;
    }

    if (order.derivativeOrders) {
      for (const derivativeOrder of order.derivativeOrders) {
        if (!derivativeOrder.validatedInvoice) {
          alert("Ве молам потврдете дека сите под-нарачки имаат фактури и информациите за нив се точно пополнети");
          return;
        }
      }

      // Update all derivative orders
      for (const derivativeOrder of order.derivativeOrders) {
        const orderRef = ref(db, `orders/${derivativeOrder.id}`);
        await update(orderRef, {
          Status: "Invoiced",
        });
      }
    }

    // Update main order
    const orderRef = ref(db, `orders/${order.id}`);
    await update(orderRef, {
      Status: "Invoiced",
    });

    alert("Успешно ажурирани нарачки");
  };

  const handleGenerateInvoice = (order) => {
    setSelectedOrder(order);
    setShowGenerateInvoiceModal(true);
  };

  const handleMultipleInvoiceModal = (order) => {
    setSelectedOrder(order);
    setShowMultipleInvoiceModal(true);
  };

  const handleCloseMultipleInvoiceModal = () =>{
    setSelectedOrder(null);
    setShowMultipleInvoiceModal(false);
  }
  const handleCloseGenerateInvoiceModal = () => {
    setShowGenerateInvoiceModal(false);
    setSelectedOrder(null);
  };

  const handlePreviewInvoice = (invoiceLink) => {
    setPreviewUrl(invoiceLink);
  };

  const handleClosePreview = () => {
    setPreviewUrl('');
  };

  const handleCreateOffer= (order) => {
    setSelectedOrder(order);
    setShowOffer(true)
  };

  const handleCloseOffer= () =>{
    setSelectedOrder(null);
    setShowOffer(false);
  }

  const handleOpenCompanyInfo = async (buyerName) => {
    setIsLoadingCompany(true);
    try {
      const companiesRef = ref(db2, 'companies');
      const snapshot = await get(companiesRef);
      if (snapshot.exists()) {
        const companies = snapshot.val();
        const companyList = Object.values(companies);
        const foundCompany = companyList.find(company => company.ime === buyerName);
        if (foundCompany) {
          setCompanyInfo(foundCompany);
          setShowCompanyInfoModal(true);
        } else {
          console.log('Фирма не е пронајдена');
        }
      } else {
        console.log('Нема податоци за фирмите');
      }
    } catch (error) {
      console.error('Проблем при вчитување на фирмите:', error);
    } finally {
      setIsLoadingCompany(false);
    }
  };

  return (
    <div className="orders-table-container">
      {isLoadingCompany && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <h2>{vendor}</h2>
      {previewUrl && (
        <div className="invoice-preview">
          <div className="preview-content">
            <button className="close-button" onClick={handleClosePreview}>&times;</button>
            <iframe src={previewUrl} title="Invoice Preview" className="preview-frame"></iframe>
          </div>
        </div>
      )}
      {selectedOrder && showOffer && (
        <div className="invoice-preview">
          <div className="preview-content">
            <div className="offer-doc-container">
              <button className="close-button" onClick={handleCloseOffer}>&times;</button>
              <Invoice documentType="Понуда" order={selectedOrder} />
            </div>
          </div>
        </div>
      )}
      <table className="orders-table orders-table-1">
        <thead>
          <tr>
            <th>Број на нарачка</th>
            <th>Датум - нарачка</th>
            <th>ОА Број</th>
            <th>д. Испорака</th>
            <th>д. Уплата</th>
            <th>Купец</th>
            <th>Коментари</th>
            <th>Док.</th>
            <th>Фирма</th>
            <th>Одговорно Лице</th>
            <th>Аванс Сума</th>
            {userRole === "admin" && <th>Подеси</th>}
          </tr>
        </thead>
        <tbody>
          {orders.map((baseOrder) => (
            <React.Fragment key={baseOrder.id}>
              {/* Base Order Row */}
              <tr className="base-order-row">
                <td>
                    <a className='order-link' href="#" onClick={() => handleEditOrder(baseOrder)}>
                      {baseOrder.OrderID}
                    </a>
                </td>
                <td>{baseOrder.OrderDate}</td>
                <td>
                  {userRole === "admin" ? (
                    <a className='order-link' href="#" onClick={() => handleMultipleInvoiceModal(baseOrder)}>
                      {baseOrder.OriginationNumber}
                    </a>
                  ) : (
                    baseOrder.OriginationNumber
                  )}
                </td>
                <td>{baseOrder.DateOfDelivery}</td>
                <td>{baseOrder.DateOfPayment}</td>
                <td>
                  <a className='order-link' href="#" onClick={() => handleOpenCompanyInfo(baseOrder.Buyer)}>
                    {baseOrder.Buyer}
                  </a>
                </td>
                <td>{baseOrder.Comment}</td>
                <td>
                  {baseOrder.OtherLink !== '' && (
                    <button onClick={() => handlePreviewInvoice(baseOrder.OtherLink)}>
                      <InvoiceIcon />
                    </button>
                  )}
                  {baseOrder.orderItems && baseOrder.orderItems.length > 0 && (
                    <button onClick={() => handleCreateOffer(baseOrder)}>
                      <strong>П</strong>
                    </button>
                  )}
                </td>
                <td>{baseOrder.Jurisdiction}</td>
                <td>{baseOrder.Username}</td>
                <td>{baseOrder.Advance}</td>
                {userRole === "admin" && !baseOrder.validatedInvoice &&(
                  <td>
                    <button onClick={() => handleGenerateInvoice(baseOrder)}>+</button>
                  </td>
                )}
                {(userRole === "admin" && baseOrder.validatedInvoice && (
                  <td>
                     <button onClick={() => handleSendToDebtors(baseOrder)}>→</button>
                  </td>
))}
              </tr>

              {/* Derivative Order Rows */}
              {baseOrder.derivativeOrders?.map((derivativeOrder) => (
                <tr key={derivativeOrder.id} className="derivative-order-row">
                  <td>
                    <span className="derivative-arrow">↳</span>
                    {derivativeOrder.OrderID}
                  </td>
                  <td>{derivativeOrder.OrderDate}</td>
                  <td>{derivativeOrder.OriginationNumber}</td>
                  <td>{derivativeOrder.DateOfDelivery}</td>
                  <td>{derivativeOrder.DateOfPayment}</td>
                  <td>
                    <a className='order-link' href="#" onClick={() => handleOpenCompanyInfo(derivativeOrder.Buyer)}>
                      {derivativeOrder.Buyer}
                    </a>
                  </td>
                  <td>{derivativeOrder.Comment}</td>
                  <td>
                    {derivativeOrder.OtherLink !== '' && (
                      <button onClick={() => handlePreviewInvoice(derivativeOrder.OtherLink)}>
                        <InvoiceIcon />
                      </button>
                    )}
                    {derivativeOrder.orderItems && derivativeOrder.orderItems.length > 0 && (
                      <button onClick={() => handleCreateOffer(derivativeOrder)}>
                        <strong>П</strong>
                      </button>
                    )}
                  </td>
                  <td>{derivativeOrder.Jurisdiction}</td>
                  <td>{derivativeOrder.Username}</td>
                  <td>{derivativeOrder.Advance}</td>
                  {userRole === "admin" && !derivativeOrder.validatedInvoice &&(
                  <td>
                    <button onClick={() => handleGenerateInvoice(derivativeOrder)}>+</button>
                  </td>
                )}

                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {editingOrder && (
        <EditOrderForm
        userRole={userRole}
          order={editingOrder}
          user={user}
          onClose={handleCloseModal}
        />
      )}
      {showGenerateInvoiceModal && (
        <GenerateInvoiceModal
          currentOrderID={selectedOrder.OrderID}
          order={selectedOrder}
          onClose={handleCloseGenerateInvoiceModal}
        />
      )}
      {showMultipleInvoiceModal && (
        <InvoiceMultipleModal
          ordersOANum={selectedOrder.OriginationNumber}
          onClose={handleCloseMultipleInvoiceModal}
        />
      )}
      {showCompanyInfoModal && companyInfo && (
        <CompanyInfoModal
          company={companyInfo}
          onClose={() => setShowCompanyInfoModal(false)}
        />
      )}
    </div>
  );
};

export default OrdersTable;
