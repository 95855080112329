import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ref, onValue } from 'firebase/database';
import { db3 } from '../../firebase';
import './Analytics.css';
import AnalyticsFilters from './AnalyticsFilters';
import KPICard from './KPICard';
import CountryHeatmap from './CountryHeatmap';
import TopCompaniesChart from './TopCompaniesChart';
import PieChart from './PieChart';
import { FaChevronDown, FaChartBar } from 'react-icons/fa';

const Analytics = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('auto');

  const [graphsCollapsed, setGraphsCollapsed] = useState(false);
  const [filterState, setFilterState] = useState({
    timeframe: 'month',
    selectedCompanies: {},
    selectedCustomers: {},
    selectedVendors: {},
    selectedLocations: {},
  });
  const [customDateRange, setCustomDateRange] = useState({
    start: '',
    end: ''
  });
  const [invoiceData, setInvoiceData] = useState([]);
  const [kpiData, setKpiData] = useState({
    totalSalesMKD: 0,
    totalSalesEUEUR: 0,
    totalSalesMKEUR: 0,
    totalInvoices: 0,
    averageLatency: 0,
    comparison: null
  });
  const [countryData, setCountryData] = useState({
    orders: {},
    incomeMKD: {},
    incomeEUR: {}
  });
  const [topCompaniesByOrders, setTopCompaniesByOrders] = useState([]);
  const [topCompaniesByIncome, setTopCompaniesByIncome] = useState([]);
  const [recentOrders, setRecentOrders] = useState([]);
  const [availableFilters, setAvailableFilters] = useState({
    companies: {},
    customers: {},
    vendors: {},
    locations: {}
  });
  const [topLatencyCompanies, setTopLatencyCompanies] = useState([]);
  const [vendorOrdersData, setVendorOrdersData] = useState([]);
  const [vendorIncomeData, setVendorIncomeData] = useState([]);

  const handleCountryClick = (country) => {
    setFilterState(prev => ({
      ...prev,
      selectedLocations: {
        [country]: { label: country }
      }
    }));
  };

  const handleCompanyClick = (companyName) => {
    const company = Object.entries(availableFilters.companies).find(([key, value]) => 
      value.label === companyName
    );

    if (company) {
      setFilterState(prev => ({
        ...prev,
        selectedCompanies: {
          [company[0]]: company[1]
        }
      }));
    }
  };

  const handleChartClick = (type, value) => {
    if (!value) return;
    
    setFilterState(prev => {
      const newState = { ...prev };
      
      switch (type) {
        case 'vendor':
          newState.selectedVendors = {
            ...prev.selectedVendors,
            [value]: !prev.selectedVendors[value]
          };
          break;
        case 'company':
          newState.selectedCustomers = {
            ...prev.selectedCustomers,
            [value]: !prev.selectedCustomers[value]
          };
          break;
        case 'location':
          newState.selectedLocations = {
            ...prev.selectedLocations,
            [value]: !prev.selectedLocations[value]
          };
          break;
        default:
          break;
      }
      
      return newState;
    });
  };

  const calculateMetricsForPeriod = (invoices, startDate, endDate) => {
    return invoices.reduce((metrics, inv) => {
      const invoiceDate = new Date(inv.date_of_issue);
      if (invoiceDate >= startDate && invoiceDate <= endDate) {
        const amount = parseFloat(inv.amount);
        if (inv.currency === 'MK-MKD') metrics.totalMKD += amount;
        else if (inv.currency === 'EU-EUR') metrics.totalEUEUR += amount;
        else if (inv.currency === 'MK-EUR') metrics.totalMKEUR += amount;
        
        metrics.totalInvoices++;

        if (inv.date_of_payment_received && inv.date_of_issue) {
          const latency = Math.floor(
            (new Date(inv.date_of_payment_received) - new Date(inv.date_of_issue)) / 
            (1000 * 60 * 60 * 24)
          );
          if (latency >= 0) {
            metrics.totalLatency += latency;
            metrics.latencyCount++;
          }
        }
      }
      return metrics;
    }, {
      totalMKD: 0,
      totalEUEUR: 0,
      totalMKEUR: 0,
      totalInvoices: 0,
      totalLatency: 0,
      latencyCount: 0
    });
  };

  const calculatePreviousPeriodDates = (timeframe) => {
    const now = new Date();
    let currentStart = new Date();
    let currentEnd = now;
    let previousStart = new Date();
    let previousEnd = new Date();

    switch (timeframe) {
      case 'week':
        currentStart.setDate(now.getDate() - 7);
        previousStart.setDate(now.getDate() - 14);
        previousEnd.setDate(now.getDate() - 7);
        break;
      case 'month':
        currentStart.setMonth(now.getMonth() - 1);
        previousStart.setMonth(now.getMonth() - 2);
        previousEnd.setMonth(now.getMonth() - 1);
        break;
      case 'quarter':
        currentStart.setMonth(now.getMonth() - 3);
        previousStart.setMonth(now.getMonth() - 6);
        previousEnd.setMonth(now.getMonth() - 3);
        break;
      case 'year':
        currentStart.setFullYear(now.getFullYear() - 1);
        previousStart.setFullYear(now.getFullYear() - 2);
        previousEnd.setFullYear(now.getFullYear() - 1);
        break;
      default:
        return null;
    }

    return {
      current: { start: currentStart, end: currentEnd },
      previous: { start: previousStart, end: previousEnd }
    };
  };

  useEffect(() => {
    const invoicesRef = ref(db3, 'invoice_details');
    console.log('Fetching invoice data...');

    const unsubscribe = onValue(invoicesRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const invoices = Object.entries(data).map(([id, invoice]) => ({
          id,
          ...invoice,
          date: invoice.date_of_issue,
          paid_date: invoice.date_of_payment_received
        }));
        
        // Build available filters
        const filters = {
          companies: {
            'somitek-mk': {
              label: 'Somitek DOO Prilep',
              type: 'MK'
            },
            'somitek-eu': {
              label: 'Somitek EOOD',
              type: 'EU'
            }
          },
          customers: {},
          vendors: {},
          locations: {}
        };

        // Filter invoices based on selected filters
        const filteredInvoices = invoices.filter(invoice => {
          // Apply all other filters except timeframe
          const companyFilters = Object.keys(filterState.selectedCompanies);
          if (companyFilters.length > 0) {
            const isMKCompany = invoice.company_name === 'Somitek DOO Prilep';
            const isEUCompany = invoice.company_name === 'Somitek EOOD';
            
            const hasMKFilter = companyFilters.includes('somitek-mk');
            const hasEUFilter = companyFilters.includes('somitek-eu');

            if (isMKCompany && !hasMKFilter) return false;
            if (isEUCompany && !hasEUFilter) return false;
            if (!isMKCompany && !isEUCompany) return false;
          }

          // Check customer if any are selected
          const customerFilters = Object.keys(filterState.selectedCustomers);
          if (customerFilters.length > 0) {
            if (!customerFilters.includes(invoice.company_name)) return false;
          }

          // Check vendor if any are selected
          const vendorFilters = Object.keys(filterState.selectedVendors);
          if (vendorFilters.length > 0) {
            if (!vendorFilters.includes(invoice.vendor)) return false;
          }

          // Check location if any are selected
          const locationFilters = Object.keys(filterState.selectedLocations);
          if (locationFilters.length > 0) {
            if (!locationFilters.includes(invoice.location_of_company)) return false;
          }

          return true;
        });

        // Calculate metrics for current and previous periods
        let currentMetrics;
        let previousMetrics;
        let periodComparison = null;

        if (typeof filterState.timeframe === 'string' && filterState.timeframe !== 'custom') {
          const periods = calculatePreviousPeriodDates(filterState.timeframe);
          currentMetrics = calculateMetricsForPeriod(filteredInvoices, periods.current.start, periods.current.end);
          previousMetrics = calculateMetricsForPeriod(filteredInvoices, periods.previous.start, periods.previous.end);

          periodComparison = {
            totalSalesMKD: ((currentMetrics.totalMKD - previousMetrics.totalMKD) / previousMetrics.totalMKD * 100) || 0,
            totalSalesEUEUR: (((currentMetrics.totalEUEUR + currentMetrics.totalMKEUR) - 
              (previousMetrics.totalEUEUR + previousMetrics.totalMKEUR)) / 
              (previousMetrics.totalEUEUR + previousMetrics.totalMKEUR) * 100) || 0,
            totalInvoices: ((currentMetrics.totalInvoices - previousMetrics.totalInvoices) / previousMetrics.totalInvoices * 100) || 0,
            averageLatency: (((currentMetrics.totalLatency / currentMetrics.latencyCount) - 
              (previousMetrics.totalLatency / previousMetrics.latencyCount)) / 
              (previousMetrics.totalLatency / previousMetrics.latencyCount) * 100) || 0
          };
        } else {
          // For custom timeframe, just calculate current metrics
          const start = typeof filterState.timeframe === 'object' ? new Date(filterState.timeframe.start) : new Date(0);
          const end = typeof filterState.timeframe === 'object' ? new Date(filterState.timeframe.end) : new Date();
          currentMetrics = calculateMetricsForPeriod(filteredInvoices, start, end);
        }

        // Set KPI data
        setKpiData({
          totalSalesMKD: currentMetrics.totalMKD,
          totalSalesEUEUR: currentMetrics.totalEUEUR,
          totalSalesMKEUR: currentMetrics.totalMKEUR,
          totalInvoices: currentMetrics.totalInvoices,
          averageLatency: currentMetrics.latencyCount > 0 ? Math.round(currentMetrics.totalLatency / currentMetrics.latencyCount) : 0,
          comparison: periodComparison
        });

        // Process country data
        const countryOrders = {};
        const countryIncomeMKD = {};
        const countryIncomeEUR = {};
        
        filteredInvoices.forEach(invoice => {
          const country = invoice.location_of_company;
          if (country) {
            // Count orders
            countryOrders[country] = (countryOrders[country] || 0) + 1;
            
            // Sum income by currency
            const amount = parseFloat(invoice.amount);
            if (invoice.currency === 'MK-MKD') {
              countryIncomeMKD[country] = (countryIncomeMKD[country] || 0) + amount;
            } else {
              countryIncomeEUR[country] = (countryIncomeEUR[country] || 0) + amount;
            }
          }
        });

        setCountryData({
          orders: countryOrders,
          incomeMKD: countryIncomeMKD,
          incomeEUR: countryIncomeEUR
        });

        // Process filters and collect data from filtered invoices
        let totalMKD = 0;
        let totalEUEUR = 0;
        let totalMKEUR = 0;
        let totalLatency = 0;
        let latencyCount = 0;
        const companyOrders = {};
        const companyIncome = {};
        const companyLatency = {};
        const vendorOrders = {};
        const vendorIncome = {};

        filteredInvoices.forEach(inv => {
          // Collect available filters
          if (inv.company_name && !filters.customers[inv.company_name]) {
            filters.customers[inv.company_name] = {
              label: inv.company_name
            };
          }

          if (inv.vendor && !filters.vendors[inv.vendor]) {
            filters.vendors[inv.vendor] = {
              label: inv.vendor
            };
          }

          if (inv.location_of_company && !filters.locations[inv.location_of_company]) {
            filters.locations[inv.location_of_company] = {
              label: inv.location_of_company
            };
          }

          // Calculate totals
          const amount = parseFloat(inv.amount);
          if (inv.currency === 'MK-MKD') totalMKD += amount;
          else if (inv.currency === 'EU-EUR') totalEUEUR += amount;
          else if (inv.currency === 'MK-EUR') totalMKEUR += amount;

          // Calculate latency
          if (inv.date_of_payment_received && inv.date_of_issue) {
            const latency = Math.floor(
              (new Date(inv.date_of_payment_received) - new Date(inv.date_of_issue)) / 
              (1000 * 60 * 60 * 24)
            );
            if (latency >= 0) {
              totalLatency += latency;
              latencyCount++;

              // Track company latency
              if (!companyLatency[inv.company_name]) {
                companyLatency[inv.company_name] = {
                  totalLatency: 0,
                  count: 0
                };
              }
              companyLatency[inv.company_name].totalLatency += latency;
              companyLatency[inv.company_name].count++;
            }
          }

          // Count orders by company
          const companyName = inv.company_name;
          companyOrders[companyName] = (companyOrders[companyName] || 0) + 1;
          companyIncome[companyName] = (companyIncome[companyName] || 0) + amount;

          // Count orders and income by vendor
          if (inv.vendor) {
            vendorOrders[inv.vendor] = (vendorOrders[inv.vendor] || 0) + 1;
            vendorIncome[inv.vendor] = (vendorIncome[inv.vendor] || 0) + amount;
          }
        });

        setAvailableFilters(filters);
        
        // Set top companies data
        const topOrders = Object.entries(companyOrders)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 5)
          .map(([name, value]) => ({ name, value }));

        const topIncome = Object.entries(companyIncome)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 5)
          .map(([name, value]) => ({ name, value }));

        setTopCompaniesByOrders(topOrders);
        setTopCompaniesByIncome(topIncome);

        // Set top latency companies
        const topLatency = Object.entries(companyLatency)
          .map(([name, data]) => ({
            name,
            value: Math.round(data.totalLatency / data.count)
          }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 5);
        setTopLatencyCompanies(topLatency);

        // Set vendor data for pie charts
        const vendorOrdersPie = Object.entries(vendorOrders)
          .map(([name, value]) => ({ name, value }))
          .sort((a, b) => b.value - a.value);
        setVendorOrdersData(vendorOrdersPie);

        const vendorIncomePie = Object.entries(vendorIncome)
          .map(([name, value]) => ({ name, value }))
          .sort((a, b) => b.value - a.value);
        setVendorIncomeData(vendorIncomePie);

        // Set recent orders if single customer selected
        if (Object.keys(filterState.selectedCustomers).length === 1) {
          const recentInvoices = filteredInvoices
            .sort((a, b) => new Date(b.date_of_issue) - new Date(a.date_of_issue))
            .slice(0, 5)
            .map(invoice => ({
              date: invoice.date_of_issue,
              amount: parseFloat(invoice.amount),
              currency: invoice.currency,
              latency: invoice.date_of_payment_received ? 
                Math.floor((new Date(invoice.date_of_payment_received) - new Date(invoice.date_of_issue)) / (1000 * 60 * 60 * 24)) : 
                null
            }));
          setRecentOrders(recentInvoices);
        } else {
          setRecentOrders([]);
        }

        setInvoiceData(filteredInvoices);
      } else {
        console.log('No invoice data available');
        setInvoiceData([]);
      }
    });

    return () => unsubscribe();
  }, [filterState]);

  const handleResize = useCallback(() => {
    if (contentRef.current && isExpanded) {
      const height = contentRef.current.scrollHeight;
      setContentHeight(`${height}px`);
    } else {
      setContentHeight('0');
    }
  }, [isExpanded]);

  useEffect(() => {
    if (isExpanded) {
      // Use RAF to ensure DOM is updated
      requestAnimationFrame(() => {
        if (contentRef.current) {
          const height = contentRef.current.scrollHeight;
          setContentHeight(`${height}px`);
        }
      });
    } else {
      setContentHeight('0');
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, isExpanded]);

  const toggleAnalytics = () => {
    setIsExpanded(!isExpanded);
  };

  const isSingleCustomerSelected = Object.keys(filterState.selectedCustomers).length === 1;

  return (
    <div className="analytics-container">
      <div className="analytics-toggle" onClick={toggleAnalytics}>
        <h2>
          <FaChartBar />
          Analytics Overview
        </h2>
        <FaChevronDown className={`analytics-toggle-icon ${isExpanded ? 'expanded' : ''}`} />
      </div>
      <div 
        className={`analytics-content-wrapper ${!isExpanded ? 'collapsed' : ''}`}
        style={{ height: contentHeight }}
      >
        <div ref={contentRef} className="analytics-inner-wrapper">
          <div className="analytics-filters">
            <AnalyticsFilters
              availableFilters={availableFilters}
              filterState={filterState}
              onFilterChange={setFilterState}
              customDateRange={customDateRange}
              setCustomDateRange={setCustomDateRange}
            />
          </div>
          <div className="analytics-main">
            <div className="kpi-section">
              <div className="kpi-cards">
                <KPICard
                  title="Total Sales (MKD)"
                  value={kpiData.totalSalesMKD.toLocaleString()}
                  currency="MKD"
                  comparison={kpiData.comparison && kpiData.comparison.totalSalesMKD}
                />
                <KPICard
                  title="Total Sales (EUR)"
                  value={(kpiData.totalSalesEUEUR + kpiData.totalSalesMKEUR).toLocaleString()}
                  currency="EUR"
                  comparison={kpiData.comparison && kpiData.comparison.totalSalesEUEUR}
                />
                <KPICard
                  title="Total Invoices"
                  value={kpiData.totalInvoices.toLocaleString()}
                  comparison={kpiData.comparison && kpiData.comparison.totalInvoices}
                />
                <KPICard
                  title="Average Payment Latency"
                  value={kpiData.averageLatency}
                  unit="days"
                  comparison={kpiData.comparison && kpiData.comparison.averageLatency}
                  inverseColors={true}
                />
              </div>
            </div>
            <div className="charts-grid">
              <div className="chart-section large">
                <h3>Orders by Country</h3>
                <CountryHeatmap 
                  data={countryData.orders}
                  metric="orders"
                  onCountryClick={(country) => handleChartClick('location', country)}
                />
              </div>
              <div className="chart-section">
                <h3>Income by Country (EUR)</h3>
                <PieChart 
                  data={Object.entries(countryData.incomeEUR).map(([name, value]) => ({
                    name,
                    value: parseFloat(value.toFixed(2))
                  }))}
                  onItemClick={(name) => handleChartClick('location', name)}
                />
              </div>
              <div className="chart-section">
                <h3>Income by Country (MKD)</h3>
                <PieChart 
                  data={Object.entries(countryData.incomeMKD).map(([name, value]) => ({
                    name,
                    value: parseFloat((value / 61.5).toFixed(2)) // Convert to EUR equivalent
                  }))}
                  onItemClick={(name) => handleChartClick('location', name)}
                />
              </div>
              <div className="chart-section">
                <h3>Companies with Highest Payment Latency</h3>
                <TopCompaniesChart 
                  data={topLatencyCompanies} 
                  metric="latency"
                  unit="days"
                  onItemClick={(name) => handleChartClick('company', name)}
                />
              </div>
              <div className="chart-section">
                <h3>Top Companies by Income</h3>
                <TopCompaniesChart 
                  data={topCompaniesByIncome} 
                  metric="income"
                  unit="EUR"
                  onItemClick={(name) => handleChartClick('company', name)}
                />
              </div>
              <div className="chart-section">
                <h3>Orders by Vendor</h3>
                <PieChart 
                  data={vendorOrdersData} 
                  onItemClick={(name) => handleChartClick('vendor', name)}
                />
              </div>
              <div className="chart-section">
                <h3>Income by Vendor</h3>
                <PieChart 
                  data={vendorIncomeData}
                  onItemClick={(name) => handleChartClick('vendor', name)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
