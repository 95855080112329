import React, { useState } from 'react';
import './Analytics.css';

const TreeFilter = ({ title, items, selectedItems, onSelectionChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleCheckboxChange = (key, item) => {
    const newSelection = { ...selectedItems };
    if (newSelection[key]) {
      delete newSelection[key];
    } else {
      newSelection[key] = item;
    }
    onSelectionChange(newSelection);
  };

  const filteredItems = Object.entries(items).filter(([key, item]) => {
    const searchLower = searchTerm.toLowerCase();
    return item.label.toLowerCase().includes(searchLower);
  });

  return (
    <div className="tree-filter">
      <div 
        className={`tree-header ${isExpanded ? 'expanded' : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="tree-arrow">{isExpanded ? '▼' : '▶'}</span>
        {title}
      </div>
      {isExpanded && (
        <div className="tree-content">
          {Object.keys(items).length > 5 && (
            <div className="tree-search">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          )}
          <div className="tree-items">
            {filteredItems.map(([key, item]) => (
              <label key={key} className="tree-item">
                <input
                  type="checkbox"
                  checked={!!selectedItems[key]}
                  onChange={() => handleCheckboxChange(key, item)}
                />
                {item.label}
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const AnalyticsFilters = ({ filterState, onFilterChange, customDateRange, setCustomDateRange, availableFilters }) => {
  const handleTimeframeChange = (event) => {
    const value = event.target.value;
    if (value === 'custom') {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);
      
      const newCustomDateRange = {
        start: thirtyDaysAgo.toISOString().split('T')[0],
        end: today.toISOString().split('T')[0]
      };
      
      setCustomDateRange(newCustomDateRange);
      onFilterChange({
        ...filterState,
        timeframe: {
          type: 'custom',
          start: thirtyDaysAgo.toISOString(),
          end: today.toISOString()
        }
      });
    } else {
      onFilterChange({
        ...filterState,
        timeframe: value
      });
    }
  };

  const handleCustomDateChange = (type, value) => {
    const newCustomDateRange = {
      ...customDateRange,
      [type]: value
    };
    setCustomDateRange(newCustomDateRange);

    if (newCustomDateRange.start && newCustomDateRange.end) {
      onFilterChange({
        ...filterState,
        timeframe: {
          type: 'custom',
          start: new Date(newCustomDateRange.start).toISOString(),
          end: new Date(newCustomDateRange.end).toISOString()
        }
      });
    }
  };

  const handleClearFilters = () => {
    onFilterChange({
      timeframe: 'month',
      selectedCompanies: {},
      selectedCustomers: {},
      selectedVendors: {},
      selectedLocations: {}
    });
    setCustomDateRange({ start: '', end: '' });
  };

  return (
    <div className="analytics-filters">
      <div className="filters-header">
        <h3>Filters</h3>
        {(Object.keys(filterState.selectedCompanies).length > 0 ||
          Object.keys(filterState.selectedCustomers).length > 0 ||
          Object.keys(filterState.selectedVendors).length > 0 ||
          Object.keys(filterState.selectedLocations).length > 0) && (
          <button className="clear-filters" onClick={handleClearFilters}>
            Clear all
          </button>
        )}
      </div>

      <div className="timeframe-filter">
        <label>Timeline</label>
        <select 
          value={typeof filterState.timeframe === 'string' ? filterState.timeframe : 'custom'}
          onChange={handleTimeframeChange}
        >
          <option value="week">Last Week</option>
          <option value="month">Last Month</option>
          <option value="quarter">Last Quarter</option>
          <option value="year">Last Year</option>
          <option value="custom">Custom Range</option>
        </select>

        {filterState.timeframe === 'custom' && (
          <div className="custom-date-range">
            <input
              type="date"
              value={customDateRange.start}
              onChange={(e) => handleCustomDateChange('start', e.target.value)}
            />
            <input
              type="date"
              value={customDateRange.end}
              onChange={(e) => handleCustomDateChange('end', e.target.value)}
            />
          </div>
        )}
      </div>

      <TreeFilter
        title="Company"
        items={availableFilters.companies}
        selectedItems={filterState.selectedCompanies}
        onSelectionChange={(newSelection) => onFilterChange({ ...filterState, selectedCompanies: newSelection })}
      />

      <TreeFilter
        title="Customer"
        items={availableFilters.customers}
        selectedItems={filterState.selectedCustomers}
        onSelectionChange={(newSelection) => onFilterChange({ ...filterState, selectedCustomers: newSelection })}
      />

      <TreeFilter
        title="Vendors"
        items={availableFilters.vendors}
        selectedItems={filterState.selectedVendors}
        onSelectionChange={(newSelection) => onFilterChange({ ...filterState, selectedVendors: newSelection })}
      />

      <TreeFilter
        title="Location"
        items={availableFilters.locations}
        selectedItems={filterState.selectedLocations}
        onSelectionChange={(newSelection) => onFilterChange({ ...filterState, selectedLocations: newSelection })}
      />
    </div>
  );
};

export default AnalyticsFilters;
